import React from 'react';

import { Box, Typography, Stack, Divider } from '@mui/material';
import { ContractItem } from './ContractItem';
import { moneyPrint, formatPercentageRate } from '@pv/common/utils';
import { Contract } from '../types';
import { TotalsRow } from '../TotalsRow';
import { LineItemSummary } from '../LineItemSummary';
interface OrderSummaryProps {
  contract: Contract;
}

export const OrderSummary = ({ contract }: OrderSummaryProps) => {
  const depositRowTitle = contract.depositRate
    ? `Deposit (${formatPercentageRate(contract.depositRate)})`
    : 'Deposit';
  return (
    <Box>
      <Stack spacing={2}>
        <Typography
          variant="h4"
          sx={{
            fontWeight: 700,
            fontSize: '20px',
            lineHeight: '30px',
          }}
        >
          Order Summary
        </Typography>
        <Stack spacing={2}>
          {contract.contractItems.map((item: any) => (
            <ContractItem
              key={item.id}
              name={item.name}
              price={item.price}
              quantity={item.quantity}
              total={item.total}
            />
          ))}
        </Stack>

        <Divider />
        <LineItemSummary contract={contract} />
        <Divider />
        <Stack spacing={1}>
          <TotalsRow>
            <Typography variant="h6">Grand Total</Typography>
            <Typography variant="h6">
              {moneyPrint(contract.totals.grand_total / 100)}
            </Typography>
          </TotalsRow>
          <TotalsRow>
            <Typography variant="subtitle1">{depositRowTitle}</Typography>
            <Typography variant="subtitle1" color="secondary">
              {moneyPrint(contract.totals.deposit / 100)}
            </Typography>
          </TotalsRow>
        </Stack>
      </Stack>
    </Box>
  );
};
