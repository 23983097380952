import React, { FC } from 'react';
import styled from 'styled-components';
import { Link, useSearchParams } from 'react-router-dom';
import { Stack, Tooltip, Typography } from '@mui/material';
import { Users, Zap } from 'react-feather';

import { PVBlack, PVGray, PVRed } from '@pv/common/colors';

import { Space } from '../types/models/space_types';
import type { Nullable } from '../types/common';
import { VenueLocationDetailsLink } from './VenueLocationDetailsLink';

/*
 * Types.
 */

interface SpacePreviewProps {
  space: Space;
}

/*
 * Constants.
 */

const StyledSpacePreviewImage = styled.img`
  width: 100%;
  border-radius: 12px;
  align-self: stretch;
  height: 100%;
  object-fit: cover;
  aspect-ratio: 4 / 3;
`;

/*
 * Components.
 */

export const SpacePreview: FC<SpacePreviewProps> = ({
  space,
}: SpacePreviewProps) => {
  const [searchParams] = useSearchParams();
  const firstVenueImage = space.venue?.images?.[0];
  const imgPath =
    space.images[0]?.url ||
    space.venue?.profileBanner ||
    firstVenueImage?.url ||
    '/default_banner.jpg';
  const venueAddress = buildVenueAddress(space);

  const buildSpacesSeatedStandingText = (
    seated: Nullable<number>,
    standing: Nullable<number>,
  ) => {
    if (!seated && !standing) {
      return '';
    } else if (seated && !standing) {
      return `${seated} seated`;
    } else if (!seated && standing) {
      return `${standing} standing`;
    } else if (seated && standing) {
      return `${seated} seated, ${standing} standing`;
    }
  };

  const buildUrlWithSearchParams = (
    pathname: string,
    searchParams: URLSearchParams,
  ) => {
    return `${pathname}?${searchParams.toString()}`;
  };

  return (
    <Stack direction="column">
      <Link
        to={buildUrlWithSearchParams(space.pathname, searchParams)}
        target={'_blank'}
        style={{ flex: 1 }}
      >
        <StyledSpacePreviewImage alt={space.name} src={imgPath} />
      </Link>
      <Stack
        direction="column"
        padding="8px 0px"
        alignItems="flex-start"
        gap="2px"
        sx={{
          minHeight: '92px',
        }}
      >
        <Stack direction="row" gap="8px" alignItems="center">
          <Typography
            variant="h3"
            sx={{
              fontSize: '16px',
              lineHeight: '150%',
              fontWeight: 500,
            }}
          >
            {space.name}
          </Typography>
          {space.expressBookEnabled && (
            <Tooltip title="Available for Express Book">
              <Zap size={16} color={PVRed} />
            </Tooltip>
          )}
        </Stack>

        <VenueLocationDetailsLink
          venueName={space.venue.name}
          venueSlug={space.venue.slug}
          venueAddress={venueAddress}
          venueNameColor={PVBlack}
          venueAddressColor="#00000099"
        />
        {(space.seated || space.standing) && (
          <Stack direction="row" gap="8px" alignItems="center">
            <Users size={16} color={PVGray} />
            <Typography variant="body2" color={PVGray}>
              {buildSpacesSeatedStandingText(space.seated, space.standing)}
            </Typography>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};

/*
 * Helpers.
 */

const buildVenueAddress = (space: Space) => {
  if (!space.venue?.address) {
    return undefined;
  }

  const address = space.venue.address;

  if (!address.city && address.state) {
    return undefined;
  } else if (address.city && !address.state) {
    return `${address.city}`;
  } else if (!address.city && address.state) {
    return `${address.state}`;
  } else {
    return `${address.city}, ${address.state}`;
  }
};
