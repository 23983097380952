export const getInitialQuantity = (
  unit: string,
  groupSize: number,
  eventDuration: number,
) => {
  switch (unit) {
    case 'item':
      return 1;
    case 'person':
      return groupSize;
    case 'hour':
      return eventDuration;
    default:
      return 1;
  }
};
